.terms-conditions-table {
    width: 100%;
    text-align: left;
    direction: ltr;
    padding: 10px 200px;
    
}

.terms-conditions-table[dir="rtl"] {
    text-align: right;
    direction: rtl;
}

.row-white {
    background-color: #ffffff;
}

.row-red {
    background-color: #f5a6a69a;
}

td {
    padding: 8px;
    border: 1px solid #ddd;
}

h3 {
    font-size: 22px;
    margin-top: 30px; 
    margin-bottom: 10px; 
}
h3::before {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #333; 
    margin: 5px;
}

p {
    font-size: 17px;
    margin: 10px 0; 
    text-align: justify;
}


li {
    font-size: 16px;
    text-align: justify;
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
}

.detail-item {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: #f9f9f9;
}


@media (max-width: 768px) {
    .terms-conditions-table {
        padding: 10px 5px; /* Reduce horizontal padding for smaller screens */
    }
}