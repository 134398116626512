.privacy-terms-section {
    padding: 20px;
    text-align: right; 
    direction: rtl;
}
.rtl {
    direction: rtl;
    text-align: right;
}

.ltr {
    direction: ltr;
    text-align: left;
}

.privacy-question {
    margin-bottom: 25px; 
}
.question-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 180px;
    display: flex;
    align-items: center; /* لضمان محاذاة السهم مع النص */
}
.arrow-icon {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #333; 
    margin: 5px; /* مسافة بين المثلث والنص */
}
.answer-text {
    font-size: 18px;
    line-height: 1.8;
    word-spacing: 0.5px;
    text-align: justify; 
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out, padding 0.4s ease-out;
    padding: 0 180px;
    margin-bottom: 10px;
}

.answer-text.active {
    max-height: 1000px;
    padding: 15px 180px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .answer-text.active{
        padding: 0 10px; 
    }
    .question-title{
        padding: 0 10px; 
    }
}