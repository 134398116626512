.Imageheader {
  padding-top: 50px;
  height: 350px;
  width: 100%;
  background-image: url("/src/assets/img/Group.png");
  background-size: contain; 
  background-position: center; 
  background-repeat: no-repeat; 
}
.UsetImageheader{
  padding-top: 50px;
  margin-top: 20px;
  height: 350px;
  width: 100%;
  background-image: url("/src/assets/img/Group2.png");
  background-size: contain; 
  background-position: center; 
  background-repeat: no-repeat;
}