.provider-terms-section {
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 5px;
    
}

.provider-title {
    font-size: 24px;
    font-weight: bold;
    color: #d2d2d2; 
}

.provider-description {
    font-size: 16px;
    color: #dbdbdb; 
}



@media (max-width: 768px) {
    .provider-terms-section {
        padding: 10px 5px; /* Reduce horizontal padding for smaller screens */
    }
}