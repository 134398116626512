/* تنسيق لمكون TermsAndConditions */
.terms-and-conditions {
    padding-bottom: 20px;
    padding-top: 20px;
    /* margin: auto; */
    max-width: 800px;

}

.terms-and-conditions h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #ffffff;
    /* text-align: center; */
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.terms-section {
    border-radius: 8px;
    padding:0  15px;
 
}

.terms-section h4 {
    font-size: 15px;
    color: #ffffff;
}

.terms-section div {
    font-size: 15px;
    line-height: 1.6;
    color: #666;
}


html {
    scroll-behavior: smooth;
}


body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
