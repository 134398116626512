.privacy-terms-section {
 
    padding: 20px;
}

.privacy-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.privacy-description {
    font-size: 22px;
    line-height: 1.5;
    text-align: justify;
    padding: 0 180px; 
}

@media (max-width: 768px) {
    .privacy-description {
        padding: 0 10px; 
    }
}